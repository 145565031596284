.container {
  max-width: 100% !important;
}

.section-1 {
  background-color: #bbf7d0;
  padding: 32px 0px;
}

.section-1 span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;

  color: #15803d;
}

.section-1 h1 {
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
  letter-spacing: -0.01em;

  color: #14532d;
}

.section-1 p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #166534;
}

.section-2 {
  background-color: #166534;
  padding: 1rem 0rem;
  color: #f0fdf4;
}

.section-2 h2 {
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
  letter-spacing: -0.01em;
  color: #f0fdf4;
}

.section-3 h3,
.section-1 h3 {
  font-weight: 700;
  font-size: 42px;
  letter-spacing: -0.01em;
}

.section-1 h4 {
  font-size: 32px;
  letter-spacing: -0.01em;
}

.section-3 {
  padding: 3rem 0;
}

.selected-nft {
  background-color: white;
}

.nft-container:hover {
  background-color: #f5fbf7;
  cursor: pointer;
}

.nft-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #14532d;
}

.my-collection {
  padding: 3.4rem 0;

  background-color: #f0fdf4;
}

.my-collection h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
  letter-spacing: -0.01em;

  color: #166534;
}

.collection-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.collection-card img {
  border-radius: 16px;
}

.collection-card h2 {
  margin-top: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;

  color: #14532d;
}

.collection-card .pill {
  background-color: #dcfce7 !important;
  border-radius: 30px;
  padding: 6px 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "salt" on;

  color: #15803d;
}

.nft-bar {
  height: 10px !important;
  background-color: #16a34a !important;
}

.nft-bar .progress-bar {
  background-color: #166534 !important;
}

.stats {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  color: #14532d;
}

.stats h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #14532d;
}

.stats .nft-price {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;

  color: #14532d;
}

.stats .btn {
  border-color: #16a34a !important;
  background-color: #16a34a !important;
  color: #f0fdf4 !important;
}

.medium {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */

  color: #14532d;
}

.big {
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  /* identical to box height, or 32px */

  color: #14532d;
}

.mint {
  transition: border 0.3s ease-in-out;
  border: 0px solid #14532d;
  width: 16px;
  height: 16px;
  background-color: #bbf7d0;
  border-radius: 50%;
}

.mint.minted {
  background-color: #14532d;
}

.mint.selected {
  border: 2px solid #14532d;
}

.pay-in .btn {
  border: none !important;
  height: 33px;
  background-color: #14532d !important;

  padding: 6px 12px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 21px !important;

  color: #ffffff !important;
}

.pay-in .btn:nth-child(2) {
  border-radius: 0 !important;
}

.pay-in .btn.no-selected {
  background-color: #bbf7d0 !important;
  color: rgba(20, 83, 45, 0.5) !important;
}

.symbol {
  color: #14532d !important;
}

.accordion-item {
  border: none !important;
}

.accordion-header button {
  padding: 1rem 0 !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}

.accordion-item button[aria-expanded="true"] {
  background-color: white;
  color: initial;
}

.accordion-item .selected-nft,
.accordion-item .selected-nft:hover {
  background-color: #bbf7d0 !important;
}

.accordion-item .nft-container:hover {
  background-color: #f5fbf7;
  cursor: pointer;
}
