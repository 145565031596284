.brand-container {
  display: flex;
  align-items: center;
}

.nav-link {
  color: var(--bs-secondary) !important;
  font-size: 18px !important;
  font-weight: 400;
}

.nav-link.active {
  color: #166534 !important;
  font-weight: 500;
}

.nav-item {
  margin: 0.5rem 1rem;
}

.brand-name {
  color: white;
  font-size: 26px;
  font-weight: bold;
}
