@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
:root {
  --bs-font-sans-serif: "Inter", sans-serif;
  --bs-body-font-weight: 600;
  --bs-body-font-size: 16px;
  --bs-body-line-height: 27px;

  --bs-body-color: #14532d;
  --bs-body-bg: white;
  --bs-modal-bg: white;

  --card-border-radius: 0.4rem;
}
