.max-width {
  max-width: 1080px !important;
}

.max-width-sm {
  max-width: 900px !important;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.d-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rounded {
  border-radius: 8px !important;
}
