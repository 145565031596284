.btn {
  border-radius: 4px !important;
  padding: 0.4rem 1rem !important;
}

.btn-primary {
  color: var(--bs-secondary) !important;
  background-color: transparent !important;
  border-color: var(--bs-secondary) !important;
  border-width: 2px !important;
  font-weight: 700 !important;
}

.btn-secondary {
  color: #14532d !important;
  background-color: #bbf7d0 !important;
  border-color: #14532d !important;
  border-width: 2px !important;
  font-weight: 700 !important;
}

.btn-close-link {
  background-color: white !important;
}

.btn-icon {
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
