.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-header .btn-close {
  margin: 0 !important;
  color:white;
  opacity:1;
}

.modal-content {
  padding: 20px;
  background-color: #000 !important;
  border-radius: var(--card-border-radius) !important;
}

.modal-title {
  color: white !important;
}

.modal-title h1 {
  font-size: 24px !important;
  line-height: 1 !important;
}

.modal-title p {
  font-size: 16px !important;
}

.connect-buttons.modal-body{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.connect-buttons.modal-body button, .connect-buttons.modal-body a{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 18px;
  background: rgb(32, 32, 32);
  width: 100%;
  border-radius: 15px;
  font-weight: 400;
}

.connect-buttons.modal-body button:hover{
  background: rgb(82, 102, 90);
}

.connect-buttons.modal-body a:hover{
  background: rgb(82, 102, 90);
}


.connect-buttons.modal-body button, .connect-buttons.modal-body a{
  display: flex !important;
  gap: 2px !important;
  padding-inline: 22px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100%;
  text-align: left;
  border: none;
  margin: 0px !important;
  color:white !important;
}
.connect-buttons.modal-body button .buttonText, .connect-buttons.modal-body a .buttonText{
  flex-grow: 1;
  font-weight: 600;
}

button svg.multiverseX-icon, a svg.multiverseX-icon{
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: currentcolor;
  vertical-align: middle;
  font-size: 12.28px;
  margin-top: 4px;
}

button svg.multiverseX-icon.ledger{
  width: 18px;
  height: 18px;
}
.connect-buttons.modal-body span.dapp-core-ui-component .btn img{
  margin-top: 4px;
}

.fade.modal-backdrop.show, .fade.modal.show{
  z-index: 1;
}
#dapp-modal, .modal-dialog.login-modal.modal-dialog-centered{
  z-index: 2;
}
