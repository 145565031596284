.card {
  border-radius: var(--card-border-radius) !important;
}

.card-header {
  background-color: white !important;
  border: none !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  color: black;
  font-size: 1.3rem;
}

.card-subtitle {
  font-size: 0.8rem;
  color: var(--card-subtitle-color);
}
